div.page-item {
    height:250px;
}
div.page-item .page-name {
    height:85px;
    overflow:hidden;
    text-overflow:ellipsis;
}
div.page-item .page-image {
    width:50px;
    height:50px;
    overflow:hidden;
    border-radius: 50%;
}
span.reload-session {
    color:#216FDB;
    cursor: pointer;
    width: 70px;
    font-weight:600;
}