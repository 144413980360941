.row-success {
  border-top: 5px solid #28a745;
  margin-bottom:10px;
}

.row-primary {
  border-top: 5px solid #007bff;
  
  margin-bottom:10px;
}

.row-info {
  border-top: 5px solid #17a2b8;
  margin-bottom:10px;
}

.row-warning {
  border-top: 5px solid #ffc107;
  margin-bottom:10px;
}

.row-danger {
  border-top: 5px solid #dc3545;
  margin-bottom:10px;
}

.row-secondary {
  border-top: 5px solid #6c757d;
  margin-bottom:10px;
}

.row-dark {
  border-top: 5px solid #343a40;
  margin-bottom:10px;
}

.component-header{
  font-weight: 800;

}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 25px;
}
.card-init-css{
  width: 100%;
  overflow: auto;
  height: 600px;
  background-color: rgb(229, 233, 235);
}

.card.card-tab{
  /* color: #B6D4D3; */
  padding: 5px;
  padding-left: 15px;
  margin: auto;
  text-align: left;
  border: 1px solid rgb(218, 223, 226);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 30px 0px;
  box-sizing: border-box;
  width: max(65%,285px);
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.card.card-tab h5 {
  color: #8B9E9D;
}
.card.card-tab > .card-body >span {
  font-size: 12px;
}
/* start dropdownbox */

.combobox {
  position: relative;
  text-align: left;
  vertical-align: baseline;
}
.combobox.container-input{
  position: relative;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  -webkit-box-align: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  min-height: 41px;
  color: rgb(95, 108, 114);
  border-width: 1px;
  border-style: solid;
  border-color: rgb(218, 223, 226);
  border-image: initial;
  border-radius: 20px;
}
.combobox.container-input > input {
  height: calc(39px);
  line-height: calc(39px);
  font-family: inherit;
  font-size: 100%;
  font-weight: 600;
  min-width: 20px;
  opacity: 0.5;
  width: 100%;
  background: transparent;
  padding: 0px 0px 0px 10px;
  border-width: initial;
  border-style: none;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  cursor: pointer;
}
.combobox.container-input > span {
  cursor: text;
  opacity: 0.45;
  pointer-events: none;
  position: absolute;
  flex: 1 1 0%;
  padding: 10px;
}
.combobox.container-input > span.button-icon{
  display: inline-block;
  fill: currentcolor;
  height: 16px;
  width: 16px;
  box-sizing: content-box;
  cursor: pointer;
  pointer-events: none;
  position: unset;
  padding: 10px;
  font-size: 24px;
}
.combobox > .listbox {
  cursor: pointer;
}
.combobox > .listbox > ul {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  max-height: 15em;
  position: absolute;
  z-index: 900;
  width: 100%;
  right: 0px;
  top: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(218, 223, 226);
  border-image: initial;
  border-radius: 4px;
  list-style: none;
  margin: 0px;
  overflow: auto;
  padding: 0px;
  
}

.combobox > .listboxPanel > ul {
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
  max-height: 15em;
  position: absolute;
  z-index: 900;
  width: 100%;
  right: 0px;
  top: 100%;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(218, 223, 226);
  border-image: initial;
  border-radius: 4px;
  list-style: none;
  margin: 0px;
  overflow: auto;
  padding-top: 5px;
  padding-left: 5px;
}

.combobox > .listbox > ul > li {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  color: rgb(150, 158, 162);
  cursor: pointer;
  padding: 10px;
  font-size: 14px;
  font-weight: 600;

}

.combobox > .listboxPanel > ul > li {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  cursor: pointer;
  font-size: 14px;

}

.combobox > .listbox > ul > li > div{
  line-height: 1.5;
  flex: 1 1 0%
}
.combobox > .listbox > ul > li:hover{
  background-color: #F4F6F6;
}
.combobox > .listbox > ul > li > div > span.name{
  font-weight: 600;
  display: block;
}

.combobox > .listbox > ul > li > div > span.id-name{
  color: #5f6c72;
  display: block;
}
div.listbox-create-facebook{
  text-align: center;
}
div.listbox-create-facebook > span{
  font-weight: 600;
}

.small-text{
  font-size: 12px;
}


button.button-cofirmsubmit{
  width: 100%;
  height: 50px;
  background-color: rgb(19, 107, 245);
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  justify-content: center;
  opacity: 1;
  transition-duration: 300ms;
  vertical-align: middle;
}
button.button-cofirmsubmit-disable{
  width: 100%;
  height: 50px;
  background-color: #DFDFDF;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  justify-content: center;
  opacity: 1;
  transition-duration: 300ms;
  vertical-align: middle;
}

button.button-testData{
  height: 50px;
  background-color: rgb(242, 242, 242);
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  text-transform: uppercase;
  color: rgb(153, 153, 153);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  justify-content: center;
  opacity: 1;
  transition-duration: 300ms;
  vertical-align: middle;
}

button.button-testData:hover{
  height: 50px;
  background-color: rgb(53, 65, 71);;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  text-transform: uppercase;
  color: #fff;
  border-radius: 10px;
  border-style: double;
  border-width: 2px;
  border-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  justify-content: center;
  opacity: 1;
  transition-duration: 300ms;
  vertical-align: middle;
}

button.button-editLSL{
  height: 50px;
  background-color: #3e64ff;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  justify-content: center;
  opacity: 1;
  transition-duration: 300ms;
  vertical-align: middle;
}

button.button-deleteLSL{
  height: 50px;
  background-color: #5f6c72;
  font-size: 16px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 10px 0px;
  text-transform: uppercase;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  justify-content: center;
  opacity: 1;
  transition-duration: 300ms;
  vertical-align: middle;
}

.tags-input {
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	min-height: 48px;
	padding: 0 8px;
	border: 1px solid rgb(214, 216, 218);
	border-radius: 6px;
	
	
}
.tags-input:focus-within {
  border: 1px solid #0052cc;
}

.tags-input > input {
  flex: 1;
  border: none;
  height: 46px;
  font-size: 14px;
  padding: 4px 0 0 0;
  
}
.tags-input > input:focus {
  outline: transparent;
}

.tags {
	display: flex;
	flex-wrap: wrap;
	padding: 0;
	margin: 8px 0 0 0;
}

.tag {
	width: auto;
	/* height: 32px; */
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	padding: 0 8px;
	font-size: 14px;
	list-style: none;
	border-radius: 18px;
	margin: 0 8px 8px 0;
	background: #0052cc;
	
	
}
.tag >.tag-close-icon {
  display: block;
  width: 16px;
  height: 16px;
  line-height: 16px;
  text-align: center;
  font-size: 14px;
  margin-left: 8px;
  border-radius: 50%;
  cursor: pointer;
}

/* end */

/* @import url('https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800'); */

* {
  box-sizing: border-box;
}
.App {
  background: #1C8EF9 !important;
  min-height: 100vh;
  display: flex;
  font-weight: 400;
  font-family: "Open Sans", sans-serif;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: "Open Sans", sans-serif;
}

body, html, .App, #root, .auth-wrapper {
  width: 100%;
  height: 100%;
}

.navbar-light {
  background-color: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: min(450px,70%);
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
  transition: all .3s;
}

.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  text-align: center;
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  text-align: right;
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: #167bff;
}

/* search component */

.has-search .form-control {
  padding-left: 2.375rem;
}

.has-search .form-control-feedback {
  position: absolute;
  z-index: 2;
  display: block;
  width: 2.375rem;
  height: 2.375rem;
  line-height: 2.375rem;
  text-align: center;
  pointer-events: none;
  color: #aaa;
}

/* end search component */

div.loadingData{
  width: 100%;height: 100%;position: absolute;z-index: 10;cursor: progress;background-color: rgb(235,235,235,0.8);
}
.btn-dark-mode{
  background-color:black !important;
  border-color:black !important;
  color:white !important
}
.btn-dark-mode:hover{
  border: 5px outset black !important;
  border-radius: 5px ;
  background-color:white !important;
  color: black !important;
  font-weight: bold
}
.meai:hover{
  font-size: 32px;
  font-weight:bold;
  color:#0052cc;
  cursor: pointer
}